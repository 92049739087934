<template>
  <div class="box">
    <div class="img"><img :src="url" alt=""></div>
    <div class="text">
      <p>问卷系统基于第三方系统搭建，需跳转到新的页面</p>
      <p>如浏览器询问是否允许打开新的页面，点击【允许】即可</p>
    </div>
    <div>
      <el-button class="btn" type="warning" @click="handlePath">跳转</el-button>
      <el-button class="btn" type="primary" plain @click="handleDownFile">下载操作手册</el-button>
    </div>
  </div>
</template>

<script>
import { fileDownload } from '@/api'
export default {
  _config: {route: {path: "list", meta: {title: "详情", keepAlive: true}}},
  data() {
    return {
      url: process.env.VUE_APP_URL_OSS + "pic/i-wait.png"
    }
  },
  methods: {
    handlePath() {
      let url = process.env.VUE_APP_URL_JUMP + '?type=2&token=' + sessionStorage.getItem('TOKEN')
      window.open(url)
    },
    handleDownFile() {
      const params = { name: '问卷管理操作手册', type: 'pdf', show: 1 }
      const api = `${process.env.VUE_APP_URL_OSS}operation/operation1.pdf`
      fileDownload(api, {}, params)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    width: 350px;
    height: 224px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover
    }
  }
  .text {
    margin: 40rem 0;
    font-size: 20rem;
    color: #666;
    text-align: center;
    p {
      margin: 10rem 0;
    }
  }
  .btn {
    width: 120rem;
    + .btn {
      margin-left: 30rem;
    }
  }
}
</style>
